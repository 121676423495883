////
/// ============================================================================
/// BUTTONS
/// ============================================================================
///
/// @link http://unicorn-ui.com/buttons/ Ispiration
///
/// @group components
////

%btn,
.btn {
    @extend .type-s;
    @include fluid-type(height, 4rem, 5rem);
    @include fluid-type(padding-right padding-left, 1.5rem, 3rem);
    -webkit-appearance: none;
    -moz-appearance: none;
    background: color-get(primary);
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-weight: fontweight-get(bold);
    margin: 0;
    max-width: 100%;
    outline: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    text-decoration: none;
    text-align: center;
    text-overflow: ellipsis;
    transition: all 0.25s ease-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:focus {
        background: color-get(primary, $lighten: -5%);
        color: #fff;
        outline: none;
        text-decoration: none;
    }

    &--secondary {
        background: color-get(secondary);
        &:hover,
        &:focus {
            background: color-get(secondary, $lighten: -5%);
        }
    }

    &--toggleoff {
        &:hover,
        &:focus {
            background: initial;
        }
    }

    &--ghost {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
    }

    &--negative {
        background: transparent;
        border: 1px solid color-get(primary);
        color: color-get(primary);
        &:hover,
        &:focus {
            border-color: color-get(primary);
            color: color-get(primary);
        }
    }

    &--expand {
        display: block;
        width: 100%;
    }

    &--center {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    &-icon {
        @include fluid-type(padding-right, 6rem, 8rem);
        align-items: center;
        display: inline-flex;
        justify-content: center;
        svg {
            pointer-events: none;
        }
        span {
            @include absolute(right 0);
        }
        &--left {
            @include fluid-type(padding-left, 6rem, 8rem);
            @include fluid-type(padding-right, 1.5rem, 3rem); // .btn
            span {
                left: 0;
                right: auto;
            }
        }
    }

    &[disabled] {
        cursor: default;
        opacity: 0.5;
    }
}
