////
/// ============================================================================
/// OBJECTS
///
/// 01. Grid
/// 02. Split
/// 03. Spinner
/// 04. Entry
/// 05. Spotlight
/// 06. Pathology
/// ============================================================================
///
/// @group components
////

/**
 * Grid
 */
.o-grid {
    @include fluid-type(padding-right padding-left, 1rem, 2rem);
    margin: 0 auto;
    max-width: $container;
    &__wrap {
        @if ($debug == true) {
            background: susy-svg-grid() no-repeat scroll;
            outline: 1px solid green;
        }
    }
}


/**
 * Split
 */
.o-split {
    width: 100%;
    @include media(">=l") {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
    }
    &__column {
        position: relative;
        @include media(">=l") {
            width: 50%;
        }
    }
    &__content {
        width: 100%;
    }
}


/**
 * Spinner
 */
.o-spinner {
    animation-name: spinner;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    border: 4px solid transparent;
    border-top: 4px solid color-get(primary);
    border-left: 4px solid color-get(primary);
    border-radius: 50%;
    cursor: wait;
    display: block;
    opacity: 1;
    pointer-events: none;
    transition: all 200ms cubic-bezier(0.72, 0.01, 0.56, 1);
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/**
 * Entry
 */
// Default
.o-entry {
    border: 1px solid color-get(gray-light);
    display: flex; // Impostato per altezza strech con Swiper
    height: auto; // Impostato per evitare l'height 100 di Swiper
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    &__link {
        @include absolute(top 0 left 0, 10);
        cursor: pointer;
        height: 100%;
        width: 100%;

        &:hover + .o-entry__wrap {
            .o-entry__title,
            .o-entry__readmore {
                color: color-get(primary);
            }
        }
    }
    &__wrap {
        position: relative;
        width: 100%;
    }
    &__image {}
    &__content {
        padding: 2rem;
    }
    &__title {
        transition: color 0.25s ease-out;
    }
}

// Course
.o-course {
    border: 0;
    margin-bottom: 0;
    &:nth-of-type(even) {
        .o-split {
            flex-direction: row-reverse;
        }
    }
    .o-split__column {
        align-items: center;
        display: flex;
        position: relative;
        text-decoration: none;
        &:hover {
            color: inherit;
            text-decoration: none;
        }
        .btn {
            float: right;
            @include media(">=l") {
                @include absolute(bottom 2rem right 2rem, 1);
            }
        }
    }
    &__text {
        color: #fff;
    }
    &__image {
        figure {
            @include media(">=m") {
                margin: -3em; // .u-pad
            }
            @include media(">=l") {
                margin: -4em; // .u-pad
            }
        }
    }
}


/**
 * Spotlight
 */
.o-spotlight {
    background: #fff;
    max-height: 100vh;
    @include media(">=l") {
        height: 1100px;
    }
    .o-split__column {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        .btn {
        }
    }
    &__content {
        position: relative;
        width: 100%;
        z-index: 1;
        @include media(">=l") {
            position: static;
        }
    }
    &__text {
        padding-bottom: 6rem;
        padding-top: 6rem;
        position: relative;
        h2, .h4 {
            color: #fff;
        }
        @include media(">=l") {
            padding-bottom: 0;
            padding-top: 0;
            h2 {
                color: initial;
            }
            .h4 {
                color: color-get(gray);
            }
        }
    }
    &__image {
        @include absolute(top 0 left 0, 0);
        height: 100%;
        width: 100%;
        @include media(">=l") {
            position: relative;
        }
        &::before {
            @include absolute(top 0 left 0, 10);
            background: rgba(#274c77, 0.6);
            content: '';
            display: block;
            height: 100%;
            width: 100%;
        }
        figure {
            height: 100%;
            width: 100%;
        }
    }
    &__actions {
        @include fluid-type(right, -4rem, -5rem); // Width .c-arrow
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        position: static;
        width: 100%;
        z-index: 1;
        @include media(">=l") {
            @include absolute(bottom 3rem, 1);
            margin-bottom: 0;
        }
        .btn {
            flex: 1;
        }
    }
}


/**
 * Pathology
 */
.o-pathology {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    @include media(">=m") {
        margin-left: -4em; // .u-pad
    }
    @include media(">=l") {
        margin-left: -6em; // .u-pad
    }
    &__item {
        border-top: 1px solid rgba(#fff, 0.8);
        &:first-of-type {
            border-top: 0;
        }
        @include media(">=m") {
            padding-left: 4em; // .u-pad
        }
        @include media(">=l") {
            padding-left: 6em; // .u-pad
        }
    }
    &__link {
        align-items: center;
        color: #fff;
        display: flex;
        padding: 1rem 0;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
        small {
            flex: 0;
            padding-right: 1em;
        }
        .h4 {
            color: currentColor;
        }
    }
}
