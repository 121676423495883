////
/// ============================================================================
/// AMAZON AFFILIATES
/// ============================================================================
///
/// @group vendors
////

.aawp {
	.aawp-product--horizontal {
		.aawp-product__title {
			font-weight: 500;
			text-decoration: none;
		}

		.aawp-product__price--current {
			font-weight: 500;
		}

		.aawp-product__footer {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin-top: 1em;
		}

		.aawp-product__pricing {
			margin: 0 2em 0 0;
		}

		img[src*=svg] {
			height: auto;
			width: auto;
		}
	}
}
