////
/// ============================================================================
/// COMPONENTS
///
/// 01. Alert
/// 02. Logo
/// 03. Vcard
/// 04. Arrow
/// 05. Avatar
/// 06. Social
/// 07. Breadcrumb
/// 08. Back To Top
/// 09. Prev Next Post Navigation
/// 10. Pagination
/// ============================================================================
///
/// @group components
////

/**
 * Alert
 *
 * Success, Info, Warning, Error
 */
.c-alert {
	background: color-get(gray-light);
	border-left: 0.5em solid rgba(#000, 0.15);
	padding: 1rem 1.5em;
	position: relative;
	width: 100%;

	&, a {
		color: color-get(text);
	}

	ul, ol {
		list-style: none;
		padding: 0;
	}

	$alerts: success, info, warning, error;

	@each $alert in $alerts {
		$background: color-get($alert);

		&--#{$alert} {
			background: $background;
			color: color-contrast($background);
		}
	}

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}


/**
 * Logo
 */
.c-logo {
	align-items: center;
	display: flex;
	line-height: 100%;
}


/**
 * Vcard
 */
.c-vcard {
	&__block {
	}

	.opening {
		display: block;

		span {
			display: inline-block;
		}

		&__day {
			width: 50%;
		}

		&__hours {
			width: 48%;
		}
	}

	a,
	a:hover {
		color: color-get(text);
		text-decoration: none;
	}
}


/**
 * Arrow
 */
.c-arrow {
	@include fluid-type(height width, 4rem, 5rem);
	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		@include fluid-type(height width, 1.5rem, 2rem);
	}

	&--negative {
		background: #fff;
		color: color-get(primary);
	}

	&--primary {
		background: color-get(primary);
		color: #fff;
	}

	&--secondary {
		background: color-get(secondary);
		color: #fff;
	}
}


/**
 * Avatar
 */
.c-avatar {
	@include aspect-ratio(1 1);
	overflow: hidden;
	position: relative;

	img {
		@include transform();
		max-width: none;
	}
}


/**
 * Social
 */
.c-social {
	@include fixed(bottom 0 left 0, 100);
	align-items: stretch;
	display: flex;
	height: 56px;
	justify-content: center;
	list-style: none;
	margin-bottom: 0;
	margin-top: 0;
	padding-left: 0;
	transform: translateY(100px);
	transition: transform 0.5s;
	width: 100%;
	@include media(">=m") {
		height: auto;
		position: relative;
		transform: none;
	}

	&.is-show {
		transform: translateY(0);
	}

	&__item {
		align-items: center;
		display: flex;
		flex: 1 1 auto;
		margin-right: 0;
		text-align: center;

		@include media(">=m") {
			flex: 0 0 auto;
			&.whatsapp {
				display: none;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		a {
			align-items: center;
			color: #fff;
			cursor: pointer;
			display: flex;
			height: 100%;
			justify-content: center;
			margin: auto;
			text-align: center;
			width: 100%;

			@include media(">=m") {
				background: transparent;
				border: 1px solid currentColor;
				border-radius: 60px;
				color: color-get(gray-light);
				height: 60px;
				margin-left: 3px;
				margin-right: 3px;
				width: 60px;
				&:hover {
					background: transparent;
				}
			}
		}

		svg {
			height: 30px;
			width: 30px;
		}

		// Color Map
		// @link http://brandcolors.net/
		$socials: (
				facebook: #3b5998,
			//feed: #f62,
				twitter: #55acee,
			//youtube: #f00,
				whatsapp: #075e54,
				linkedin: #0077b5
		);

		@each $name, $color in $socials {
			&.#{$name} a {
				background: $color;

				&:hover {
					background: darken($color, 10%);
				}
			}
			@include media(">=m") {
				&.#{$name} a {
					background: transparent;

					&:hover {
						background: transparent;
						border-color: $color;
						color: $color;
					}
				}
			}
		}
	}
}


/**
 * Breadcrumb
 */
.c-breadcrumb {
	@extend .type-xs;
	opacity: 0.9;

	&__wrap {
		span {
			align-items: center;
			display: flex;
			overflow: hidden;
			padding-left: .5em;
			white-space: nowrap;
		}

		& > span {
			justify-content: center;
			padding-left: 0;

			& > span > a {
				flex: 0 0 auto;
				min-width: auto;
			}
		}

		a, strong {
			flex: 0 1 auto;
			min-width: 30px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

		}

		a {
			color: color-get(gray);
			padding-right: .5em;
			text-decoration: none;

			&:hover {
				color: color-get(primary);
				flex: 0 0 auto;
				min-width: auto;
			}
		}

		strong {
			color: color-get(gray-dark);
			font-weight: fontweight-get(normal);
			padding-left: .5em;
		}
	}
}


/**
 * Back To Top
 */
.c-backtotop {
	$size: 50px;
	@include fixed(bottom ($size / 2) right ($size / 2), 99);
	background: color-get(primary);
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	color: #fff;
	display: inline-block;
	height: $size;
	opacity: 0;
	transition: opacity .3s 0s, visibility 0s .3s, background .3s 0s;
	visibility: hidden;
	width: $size;

	svg {
		@include transform();
		height: ($size / 2);
		width: ($size / 2);
	}

	body.single & {
		@include media("<m") {
			bottom: 70px;
			right: 15px
		}
	}

	@include media(">=m") {
		$size: 60px;
		bottom: ($size / 2);
		height: $size;
		right: ($size / 2);
		width: $size;
		svg {
			height: ($size / 2);
			width: ($size / 2);
		}
	}
	@include media(">=l") {
		$size: 70px;
		bottom: ($size / 2);
		height: $size;
		right: ($size / 2);
		width: $size;
		svg {
			height: ($size / 2);
			width: ($size / 2);
		}
	}

	&.c-backtotop--show,
	&.c-backtotop--fade-out,
	&:hover {
		transition: opacity 0.3s 0s, visibility 0s 0s, background .3s 0s;
	}

	// the button becomes visible
	&.c-backtotop--show {
		opacity: 1;
		visibility: visible;
	}

	// if the user keeps scrolling down, the button is out of focus and becomes less visible
	&.c-backtotop--fade-out {
		opacity: 0.7;
	}

	&:hover {
		color: #fff;
		opacity: 1;
	}
}


/**
 * Prev Next Post Navigation
 */
.c-prev-next {
	&__list {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		list-style: none;
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;
	}

	&__item {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
		@include media(">=m") {
			margin-right: gutter();
			width: span(5);
			&:last-of-type {
				margin-right: 0;
			}
			&--next {
				a {
					flex-direction: row-reverse;

					figure {
						margin-left: 1.5em;
						margin-right: 0;
					}
				}
			}
		}

		a {
			align-items: center;
			color: #fff;
			display: flex;
			height: 100%;
			justify-content: flex-start;
			padding: 1rem 0;
			width: 100%;

			&:hover {
				color: #fff;
			}
		}

		&--cat {
			display: none;
			@include media(">=m") {
				display: flex;
				width: span(2);
			}

			a {
				justify-content: center;
			}

			span {
				display: none;
			}
		}

		svg {
			height: 38px;
			margin: 0.5em;
			width: 38px;
		}

		figure {
			flex: 0 0 auto;
			margin-right: 1.5em;
			width: span(3);
			@include media(">=m") {
				width: span(2 of 5);
			}
		}

		h5 {
			color: #fff;
		}
	}
}


/**
 * Pagination
 */
.c-pagination {
	@include clearfix;
	text-align: center;

	&__current,
	&__url,
	&__dots {
		@include aspect-ratio(1 1);
		align-items: center;
		color: color-get(text);
		display: inline-flex;
		justify-content: center;
		line-height: 3rem;
		margin-left: -1px;
		vertical-align: middle;
		width: 3em;
	}

	&__url {
		border: 1px solid color-get(gray-light);
		color: color-get(text);
		cursor: pointer;

		&:hover {
			background: color-get(secondary);
			border-color: color-get(secondary);
			color: #fff;
		}
	}

	&__current {
		background: color-get(primary);
		color: #fff;
	}
}
