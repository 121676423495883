////
/// ============================================================================
/// GDPR IUBENDA
///
/// 01. Privacy Policy
/// 02. Cookie Policy
/// ============================================================================
////


/**
 * Privacy Policy
 */
.iubenda_legal_document {
	& > h1 {
		display: none;
	}
}

/**
 * Cookie Policy
 */
.iub_content {
	& > h2 {
		display: none;
	}

	ul {
		list-style: none;
		margin-left: 0;
		padding-left: 0;
	}

	ul li h3,
	ul li h4 {
		font-size: inherit;
	}
}