////
/// ============================================================================
/// MENU
///
/// 01. Wrapper
/// 02. Trigger
/// 03. Sizes
/// 04. Dropdown
/// 05. Close - mobile version only
/// 06. Dropdown Content
/// 07. No Js
/// ============================================================================
///
/// @link https://codyhouse.co/demo/mega-dropdown/index.html#0
/// @group layout
////

/**
 * Wrapper
 */
//.nav {
//    @extend %heading-fontfamily;
//    display: flex;
//    flex: 1;
//    justify-content: flex-end;
//    position: relative;
//}

$color-1: color-get(gray-darker);
$color-2: color-get(secondary);
$color-3: #fff;

.nav {
    @extend %heading-fontfamily;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nav__trigger {
    @include fluid-type(font-size line-height, 13px, 15px);
    border-radius: $border-radius;
    color: color-get(gray);
    display: block;
    font-weight: fontweight-get(bold);
    padding: 0 40px 0 20px;
    position: relative;
    text-decoration: none;
    text-indent: -999em;
    text-transform: uppercase;
    @include media(">=l") {
        text-indent: 0;
    }
    &:hover {
        color: color-get(secondary);
        text-decoration: none;
        span {
            &,
            &::before,
            &::after {
                background: $color-2;
            }
        }
    }

    // Hamburger
    span {
        margin-top: -1px;

        // Line in the center
        @include absolute(top 50% right 5px);
        transition: background 0.2s;
        width: 18px;
        &,
        &::before,
        &::after {
            background: color-get(text);
            display: inline-block;
            height: 0.1em;
            position: absolute;
            transition: transform 0.2s;
        }

        // Other 2 lines
        &::before,
        &::after {
            content: '';
            right: 0;
            transform: translateZ(0);
            transform-origin: 0 50%;
            backface-visibility: hidden;
        }
        &::before {
            top: -6px; // Top line
            width: 24px;
        }
        &::after {
            top: 6px; // Bottom line
            width: 22px;
        }
    }

    @include media(">=m") {
        &.dropdown-is-active {
            color: $color-2;
            span {
                background: transparent;
                width: 24px;

                &::before,
                &::after {
                    background: $color-2;
                    width: 24px;
                }

                &::before {
                    transform: translateX(4px) translateY(-3px) rotate(45deg);
                }
                &::after {
                    transform: translateX(4px) translateY(2px) rotate(-45deg);
                }
            }
        }
    }
}

.nav__title,
.menu a,
.menu ul a {
    height: 50px;
    line-height: 50px;

    @include media(">=s") {
        height: 60px;
        line-height: 60px;
    }

    @include media(">=m") {
        height: 50px;
        line-height: 50px;
    }
}

.nav__title,
.menu a,
.menu ul a,
.menu .menu__item--divider {
    padding: 0 20px;
}

.nav__dropdown {
    background-color: $color-1;
    color: $color-3;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    /* Force Hardware Acceleration */
    backface-visibility: hidden;
    transform: translateZ(0);
    transform: translateY(-100%);
    transition: transform .5s 0s, visibility 0s .5s;

    .nav__title {
        /* dropdown title - mobile version only */
        background-color: $color-1;
        border-bottom: 1px solid tint($color-1, 8%);
        color: $color-3;
        position: relative;
        z-index: 1;
    }

    .nav__close {
        /* 'X' close icon - mobile version only */
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        z-index: 1;

        /* image replacement */
        color: transparent;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &::after,
        &::before {
            /* this is the 'X' icon */
            background-color: $color-3;
            bottom: auto;
            content: '';
            height: 20px;
            left: 50%;
            position: absolute;
            right: auto;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 2px;
        }

        &::after {
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        &::before {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }

    }

    &.dropdown-is-active {
        transform: translateY(0);
        transition: transform 0.5s 0s, visibility 0s 0s;
        visibility: visible;
    }

    @include media(">=s") {
        .nav__close {
            top: 5px;
        }
    }

    @include media(">=m") {
        position: absolute;
        top: 2rem;
        /* reset style*/
        background-color: $color-3;
        box-shadow: 0 4px 12px rgba(#000, .1);
        color: $color-1;
        height: auto;
        opacity: 0;
        transform: translateX(0);
        transform: translateY(30px);
        transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
        width: auto;

        .open-to-left & {
            /* use the .open-to-left class if you want to open the dropdown on the left */
            left: auto;
            right: 0;
        }

        .nav__title, .nav__close {
            /* on desktop - hide title and 'X' */
            display: none;
        }

        &.dropdown-is-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
        }
    }
}

.menu__item,
.menu__link {
    margin: 0;
    padding: 0;
    border: 0;
}

.menu,
.menu ul {
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 50px;
    position: absolute;
    top: 0;
    transition: transform 0.3s;
    width: 100%;

    a {
        color: $color-3;
        display: block;

        /* truncate text with ellipsis if too long */
        border-color: tint($color-1, 8%);
        border-style: solid;
        border-top-width: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    li:first-of-type > a {
        border-top-width: 0;
    }

    li:last-of-type > a {
        border-bottom-width: 1px;
    }

    .menu__item--divider {
        background-color: shade($color-1, 30%);
        color: tint($color-1, 30%);
        font-size: 11px;
        font-weight: fontweight-get(bold);
        letter-spacing: 1px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-transform: uppercase;
        a {
            // Reset
            border: none;
            cursor: default;
            font-size: 11px;
            height: auto;
            line-height: 100%;
            padding: 0;
            @include media(">=m") {
                color: tint($color-1, 30%);
            }
        }
    }

    .menu__item--divider + li > a {
        border-top-width: 0;
    }

    a, .menu__item--search, .menu__item--divider {
        /* Force Hardware Acceleration */
        transform: translateZ(0);
        transition: opacity .3s, transform .3s;
        backface-visibility: hidden;
    }

    &.is-hidden {
        /* push the secondary dropdown items to the right */
        transform: translateX(100%);
    }

    &.is-hidden > li > a,
    &.is-hidden > li > .menu__item--search,
    &.is-hidden > .menu__item--divider,
    &.move-out > li > a,
    &.move-out > li > .menu__item--search,
    &.move-out > .menu__item--divider {
        /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
        opacity: 0;
    }

    &.move-out > li > a,
    &.move-out > li > .menu__item--search,
    &.move-out > .menu__item--divider {
        /* push the dropdown items to the left when secondary dropdown slides in */
        transform: translateX(-100%);
    }

    .dropdown-is-active & {
        -webkit-overflow-scrolling: touch;
    }

    @include media(">=s") {
        padding-top: 60px;
        a {
            font-size: 18px;
        }
    }

    @include media(">=m") {
        overflow: visible;
        padding-top: 0;

        a {
            border-color: darken($color-3, 8%);
            color: $color-1;
            font-size: 15px;
            height: 50px;
            line-height: 50px;
        }

        .menu__item--divider {
            background-color: transparent;
            border-top: 1px solid darken($color-3, 8%);
            color: darken($color-3, 30%);
        }

        .menu__item--divider + li > a {
            border-top-width: 1px;
        }

        &.is-hidden > li > a,
        &.is-hidden > li > .menu__item--search,
        &.is-hidden > .menu__item--divider,
        &.move-out > li > a,
        &.move-out > li > .menu__item--search,
        &.move-out > .menu__item--divider {
            /* reset mobile style */
            opacity: 1;
        }
    }
}

.menu {
    .see-all a {
        /* different style for the See all links */
        color: $color-3;
    }

    .menu__sub-menu--type-onelevel .menu__link {
        height: 60px;
        line-height: 60px;
    }

    .menu__sub-menu--type-gallery .menu__link,
    .menu__sub-menu--type-icons .menu__link {
        /* items with picture (or icon) and title */
        height: 80px;
        line-height: 80px;

        h3 {
            /* truncate text with ellipsis if too long */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .menu__sub-menu--type-gallery .menu__link {
        padding-left: 90px;
    }

    .menu__sub-menu--type-gallery img {
        display: block;
        height: 40px;
        left: 20px;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        width: auto;
    }

    .menu__sub-menu--type-icons .menu__link {
        padding-left: 75px;
        position: relative;

        p {
            color: $color-1;
            display: none;
            /* hide description on small devices */
            font-size: 12px;
        }

        svg {
            content: '';
            display: block;
            height: 50px;
            left: 10px;
            margin-top: -25px;
            position: absolute;
            top: 50%;
            width: 50px;
        }
    }

    @include media(">=m") {
        /* reset mobile style */
        position: static;
        height: auto;
        width: 280px;

        > li:last-of-type a {
            border-bottom: none;
        }

        .no-touch & > li:not(.menu__item--parent) a:hover {
            color: $color-2;
        }

        &.move-out > li > a,
        &.move-out > li > .menu__item--search,
        &.move-out > .menu__item--divider {
            /* reset mobile style */
            opacity: 1;
            transform: translateX(0);
        }

        .menu__sub-menu--depth-1,
        .menu__sub-menu--type-onelevel,
        .menu__sub-menu--type-gallery,
        .menu__sub-menu--type-icons {
            @include clearfix;
            background-color: $color-3;
            box-shadow: 0 2px 14px rgba(#000, .1);
            height: auto;
            left: 100%;
            transform: translateX(0);

            .open-to-left & {
                /* use the .open-to-left class if you want to open the dropdown on the left */
                left: auto;
                right: 100%;
            }

            &.is-hidden {
                /* reset mobile style */
                transform: translateX(0);
            }

            &.fade-in {
                /* animate secondary dropdown items when hovering over of of the .menu list items */
                animation: cd-fade-in .2s;
            }

            &.fade-out {
                /* animate secondary dropdown items when hovering over of of the .menu list items */
                animation: cd-fade-out .2s;
            }

            > .go-back {
                display: none;
            }

            > .see-all {
                border: 0;
                bottom: 20px;
                height: 45px;
                position: absolute;
                text-align: center;

                a {
                    background: $color-2;
                    height: 100%;
                    line-height: 45px;
                    margin: 0;
                    padding: 0;
                    pointer-events: auto;
                    transition: color .2s, background-color .2s;
                }
            }

            .menu__link, a {
                border: none;
            }
        }

        .menu__sub-menu--type-gallery,
        .menu__sub-menu--type-icons {
            padding: 20px 30px 100px;

            > .see-all {
                width: calc(100% - 60px);
            }
        }
        
        .menu__sub-menu--type-onelevel > li {
            border-right: none;
            border-top-width: 1px;
            float: none;
            height: auto;
            margin: 0;
            padding: 0;
            width: 100%;
            &:nth-of-type(1) {
                border-right-width: 0;
            }
        }

        .menu__sub-menu--type-icons > li,
        .menu__sub-menu--depth-1 > li {
            float: left;
            width: 50%;
        }

        .menu__sub-menu--depth-1 {
            overflow: hidden;
            padding-bottom: 65px;
            width: 550px;

            &::before {
                /* this is the separation line in the middle of the .menu__sub-menu--depth-1 element */
                background-color: darken($color-3, 8%);
                content: '';
                height: 1px;
                left: 15px;
                position: absolute;
                top: 290px;
                width: 520px;
            }

            > li > a {
                color: $color-2;
                font-size: 16px;
                height: 30px;
                line-height: 30px;
                margin-bottom: 10px;
                pointer-events: none;

                &::after, &::before {
                    /* hide the arrow */
                    display: none;
                }
            }

            &.move-out > li > a {
                /* reset mobile style */
                transform: translateX(0);
            }

            > li {
                border-color: darken($color-3, 8%);
                border-right-width: 1px;
                border-style: solid;
                height: 250px;
                margin: 20px 0;
                padding: 0 30px;
            }

            > li:nth-of-type(2n) {
                border-right-width: 0;
            }

            > li > ul {
                /* reset mobile style */
                height: auto;
                position: relative;
                transform: translate(0);

                > .go-back {
                    display: none;
                }
            }

            a {
                font-size: 13px;
                height: 25px;
                line-height: 25px;
                padding-left: 0;

                .no-touch &:hover {
                    color: $color-2;
                }
            }

            ul {
                height: auto;
                overflow: hidden;
                padding-bottom: 25px;
            }

            .go-back a {
                color: transparent;
                padding-left: 20px;

                .no-touch &:hover {
                    color: transparent;
                }

                &::before, &::after {
                    left: 0;
                }
            }

            .see-all {
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .menu__sub-menu--type-gallery {
            width: 600px;
            padding-bottom: 100px;

            > li {
                float: left;
                margin-right: 4%;
                width: 48%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }

            .menu__link {
                color: $color-2;
                height: auto;
                line-height: normal;
                margin-bottom: 2em;
                padding: 0;
            }

            > li:nth-last-of-type(2) a,
            > li:last-of-type a {
                margin-bottom: 0;
            }

            img {
                height: auto;
                margin: 0 0 0.6em;
                position: static;
                width: 100%;
            }
        }

        .menu__sub-menu--type-onelevel {
            padding: 20px 30px 80px;
            width: 280px;

            .menu__link {
                color: $color-1;
                height: 40px;
                line-height: 40px;
                padding: 0;
                pointer-events: auto;

                &:hover {
                    color: $color-2;
                }
            }

            > .see-all {
                width: calc(100% - 60px);
            }
        }

        .menu__sub-menu--type-icons {
            width: 600px;

            .menu__link {
                height: 80px;
                line-height: 1.2;
                padding: 24px 0 0 85px;

                &:hover {
                    background: darken($color-3, 8%);
                }

                h3 {
                    color: $color-2;
                    font-weight: bold;
                }

                p {
                    display: block;
                    font-size: 12px;
                }

                svg {
                    left: 25px;
                }
            }
        }

        > .menu__item--parent > ul {
            visibility: hidden;
        }

        > .menu__item--parent > ul.is-active {
            /* when hover over .menu items - show subnavigation */
            visibility: visible;
        }

        > .menu__item--parent > .menu__sub-menu--depth-1.is-active > li > ul {
            /* if .menu__sub-menu--depth-1 is visible - show also subnavigation */
            visibility: visible;
        }

        > .menu__item--parent > a.is-active {
            /* hover effect for .menu items with subnavigation */
            box-shadow: inset 2px 0 0 $color-2;
            color: $color-2;

            &::before,
            &::after {
                background: $color-2;
            }
        }

        .open-to-left & > .menu__item--parent > a.is-active {
            box-shadow: inset -2px 0 0 $color-2;
        }
    }
}

@keyframes cd-fade-in {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes cd-fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: visible;
    }
}

.menu__item--search input[type="search"] {
    appearance: none;
    background-color: tint($color-1, 8%);
    border: none;
    border-radius: 0;
    color: $color-3;
    height: 50px;
    padding: 0 20px;
    width: 100%;
    &::placeholder {
        color: $color-3;
    }

    &:focus {
        background: $color-3;
        color: $color-1;
        outline: none;

        &::placeholder {
            color: rgba($color-1, .4);
        }
    }

    @include media(">=m") {
        background-color: darken($color-3, 8%);

        &::placeholder {
            color: darken($color-3, 30%);
        }
    }
}

.menu__item--parent > a,
.go-back a {
    position: relative;

    &::before, &::after {
        /* arrow icon in CSS - for element with nested unordered lists */
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -1px;
        display: inline-block;
        height: 2px;
        width: 10px;
        background: $color-3;
        backface-visibility: hidden;
    }

    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }

    @include media(">=m") {
        &::before,
        &::after {
            background: darken($color-3, 30%);
        }
    }
}

.menu__item--parent > a {
    padding-right: 40px;

    &::before, &::after {
        /* arrow goes on the right side - children navigation */
        right: 20px;
        transform-origin: 9px 50%;
    }
}

@include media(">=m") {
    .open-to-left .menu > .menu__item--parent > a {
        padding-left: 40px;
        padding-right: 20px;

        &::before,
        &::after {
            left: 20px;
            right: auto;
            transform-origin: 1px 50%;
        }
    }
}

.menu .go-back a {
    padding-left: 40px;

    &::before, &::after {
        /* arrow goes on the left side - go back button */
        left: 20px;
        transform-origin: 1px 50%;
    }
}

.no-js .nav {
    &:hover .nav__dropdown {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
    .nav__close {
        display: none;
    }
}
