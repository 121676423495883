////
/// ============================================================================
/// HEADER
///
/// 01. Variables
/// 02. Header
/// ============================================================================
///
/// @group layout
////

/**
 * Variables
 */
$header-height-mobile: 60px;
$header-height-desktop: 160px;
$header-height-desktop-fixed: 100px;

/**
 * Header
 */
.header {
	@include absolute(top 0, 9999);
	height: $header-height-mobile;
	width: 100%;
	@include media(">=l") {
		height: $header-height-desktop;
	}

	&__headline {
		&-wrap {
			align-items: center;
			display: flex;
			height: $header-height-mobile;
			justify-content: space-between;
			padding: 0 gutter();
			position: relative;
			@include media(">=l") {
				height: $header-height-desktop;
			}
		}
	}

	// Logo
	&__logo {
		margin: 0;

		svg {
			height: 100%;
			width: 100%;
		}

		.c-logo {
			&__default {
				display: none;
				height: 78x;
				width: 140px;
				@include media(">=l") {
					display: block;

					.header--fixed & {
						display: none;
					}
				}
			}

			&__symbol {
				height: 30px;
				width: 70px;
				@include media(">=l") {
					display: none;

					.header--fixed & {
						display: block;
						height: 74px;
						width: 170px;
					}
				}
			}
		}
	}

	&__nav {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: flex-end;
	}
}

/**
 * Nav Top
 */
.c-nav-top {
	@include fluid-type(font-size line-height, 13px, 15px);
	display: none;
	list-style: none;
	margin-bottom: 0;
	margin-top: 0;
	padding-left: 0;

	@include media(">=s") {
		display: block;
	}

	&__item {
		display: inline-block;
		margin-right: 1em;

		&:first-of-type {
			display: none;

			@include media(">=m") {
				display: inline-block;
			}
		}
	}

	&__link {
		color: color-get(gray);
		display: block;
		font-weight: fontweight-get(bold);
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: color-get(secondary);
			text-decoration: none;
		}
	}
}
