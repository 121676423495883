////
/// ============================================================================
/// ARCHIVE
///
/// 01. Base
/// 02. Default
/// 03. Course
/// ============================================================================
///
/// @group pages
////

/**
 * Base
 */
.blog,
.archive {
    padding-top: 60px; // Header Mobile
    @include media(">=l") {
        padding-top: 200px; // Header Desktop
    }

    &__header {
        text-align: center;
    }
}


/**
 * Default
 *
 * Usato in: Therapy, Pathology, Posts
 */
.archive-default {
    &__list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .o-entry {
            width: 100%;

            // Swiper
            .swiper-container-horizontal & {
                margin-right: gutter();
                &:last-of-type {
                    margin-right: 0!important;
                }
            }

            @include media(">=s") {
                margin-right: gutter();
                width: span(6);
                &:nth-of-type(2n),
                &:last-of-type {
                    margin-right: 0;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(3n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
            @include media(">=m") {
                width: span(4);
                &,
                &:nth-of-type(2n) {
                    margin-right: gutter();
                }
                &:nth-of-type(3n),
                &:last-of-type {
                    margin-right: 0;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(3n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
            @include media(">=l") {
                width: span(3);
                &,
                &:nth-of-type(3n) {
                    margin-right: gutter();
                }
                &:nth-of-type(4n),
                &:last-of-type {
                    margin-right: 0;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(4n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
        }

        // Swiper
        .swiper-container-horizontal & {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        &.swiper-wrapper {
            box-sizing: border-box;
        }
    }
}


/**
 * Course
 */
.archive-course {
    &__list {}
}
