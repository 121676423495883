////
/// ============================================================================
/// SINGULAR
///
/// 01. Singular
/// 02. Layout
/// 03. Contacts
/// ============================================================================
///
/// @group pages
////

/**
 * Singular
 */
.single,
.page {
	padding-top: 60px; // Header Mobile
	@include media(">=l") {
		padding-top: 200px; // Header Desktop

		&.single-landing {
			padding-top: 160px;
		}
	}
}

/**
 * Layout
 */
.single {
	&__header {
		text-align: center;

		p {
			color: color-get(gray);
		}

		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			width: span(10);
			p {
				padding-left: span(2 of 10 narrow);
				padding-right: span(2 of 10 narrow);
			}
		}
	}

	&__image {
		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			width: span(10);
		}
	}

	&__body {
		h2, h3, h4, h5, h6,
		p, ul, ol {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h2 {
			margin-bottom: 1.5rem;
			margin-top: 3.5rem;
		}

		h3 {
			margin-bottom: 1.5rem;
			margin-top: 2.5rem;
		}

		.alignleft {
			margin: 0 0.5rem 0.5rem 0;
		}

		.aligright {
			margin: 0 0 0.5rem 0.5rem;
		}

		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			width: span(8);

			// Guttemberg
			& > .wp-block-image,
			& > .wp-block-embed,
			& > .wp-block-gallery {
				text-align: center;
				width: auto;
			}

			.testimonials,
			& > .wp-block-image,
			& > .wp-block-embed {
				margin: 2.5rem -(#{span(1 of 8) + gutter(1 of 8)});
			}

			& > .wp-block-image {
				max-width: none;
			}

			& > .wp-block-gallery {
				$grid: -(span(1 of 8) + gutter(1 of 8));
				margin: 2.5rem calc(#{$grid} - 8px);
			}

			.wp-block-columns.has-2-columns {
				align-items: center;
			}
		}
	}

	&__share {
	}
}

/**
 * Contacts
 */
.contacts {
}


/**
 * Iframe
 */
iframe[src*="google.com"] {
	width: 100%;
}

.wp-block-embed iframe {
	aspect-ratio: 16 / 9;
	height: 100%;
	max-width: 100%;
	width: 100%;
}

.wp-block-image {
	.aligncenter,
	.alignleft,
	.alignright,
	&.aligncenter,
	&.alignleft,
	&.alignright {
		display: block;

		& > figcaption {
			display: block;
			font-size: 0.9rem;
		}
	}
}
