////
/// ============================================================================
/// SELECTION
/// ============================================================================
///
/// @group components
////

::-moz-selection {
    background: color-get(gray-light);
    color: inherit;
    text-shadow: none;
}
::selection {
    background: color-get(gray-light);
    color: inherit;
    text-shadow: none;
}
