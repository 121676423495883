////
/// ============================================================================
/// GRAVITYFORMS
/// ============================================================================
////

.gform_wrapper.gravity-theme {
	.gform_footer button,
	.gform_footer input,
	.gform_page_footer button,
	.gform_page_footer input {
		@extend %btn;
	}

	.gform_footer [type="submit"] {
		width: 100%;
	}

	.ginput_container_consent {
		position: relative;
	}

	.gform_ajax_spinner {
		max-width: 40px;
		margin-left: 1em;
	}
}
