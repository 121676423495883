////
/// ============================================================================
/// COMPONENT / Dropdown
/// ============================================================================
///
/// @link https://codyhouse.co/gem/css-multi-level-accordion-menu/
///
/// @group components
////

.c-dropdown {
	padding: 0;

	&__checkbox {
		opacity: 0;
		position: absolute;

		&:checked + label + .c-dropdown__panel,
		&:checked + label:nth-of-type(n) + .c-dropdown__panel {
			display: block; // use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors
		}

		@at-root &:checked + label svg {
			transform: rotate(-180deg);
		}
	}

	&__label {
		border: 1px solid color-get(gray-light);
		color: color-get(text);
		cursor: pointer;
		display: block;
		font-size: inherit;
		font-weight: inherit;
		margin: 0;
		padding: 1rem;
		position: relative;
		width: 100%;

		svg {
			@include center(right 1em, 24px, 24px, 1);
			transition: all .5s;
		}
	}

	&__panel {
		border: 1px solid color-get(gray-light);
		display: none;
		list-style: none;
		margin: -1px 0 0 0;
		padding: 0;
	}

	&.-expand {
		margin-bottom: 2rem;
		@include media(">=m") {
			margin-bottom: 0;
		}

		.c-dropdown__checkbox,
		.c-dropdown__label {
			@include media(">=m") {
				display: none;
			}
		}

		.c-dropdown__panel {
			@include media(">=m") {
				align-items: center;
				border: 0;
				display: flex;
				flex-wrap: wrap;
				gap: 0.5em;
				justify-content: center;
				margin: 0 auto;
				max-width: 1200px;
				padding: 0;
				width: 100%;

				li {
					border-bottom: none;

					&.is-active a {
						background: color-get(primary);
						border-color: color-get(primary);
						color: #fff;
					}

					a {
						border: 1px solid color-get(gray-light);
						border-radius: 9em;
						display: block;
						font-size: 0.9em;
						padding: 0.5rem 1.5rem;
						text-decoration: none;
					}
				}
			}
		}
	}
}
