////
/// ============================================================================
/// COMPONENT / Star Ratings
/// ============================================================================
///
/// @group components
////

.c-star-rating {
	margin-top: 0.2em;

	i {
		border-bottom: 0.7em solid #fc0;
		border-left: 0.3em solid transparent;
		border-right: 0.3em solid transparent;
		display: inline-block;
		font-size: 10px;
		height: 0;
		margin-bottom: 1.2em;
		margin-left: 5px;
		margin-right: 5px;
		position: relative;
		width: 0;

		&:before,
		&:after {
			border-bottom: .7em solid gold;
			border-left: 1em solid transparent;
			border-right: 1em solid transparent;
			content: '';
			display: block;
			height: 0;
			left: -1em;
			position: absolute;
			top: .6em;
			transform: rotate(-35deg);
			width: 0;
		}

		&:after {
			transform: rotate(35deg);
		}
	}
}
