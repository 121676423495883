////
/// ============================================================================
/// COMPONENT / Submenu
/// ============================================================================
///
/// @group components
////

.c-catmenu {
    list-style: none;
    padding: 0.5rem 1rem;

    li {
        border-bottom: 1px solid color-get(gray-light);
        &:last-of-type {
            border-bottom: 0;
        }

        a {
            color: color-get(text);
            display: block;
            padding: 0.5rem 0;
            text-decoration: none;
        }

        &.is-active a {
            color: $link-color;
        }
    }
}
