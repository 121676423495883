////
/// ============================================================================
/// HOME
///
/// 01. Home
/// 02. Spotlight
/// 03. Therapy & Post
/// 04. Pathology
/// 05. Course
/// ============================================================================
///
/// @group pages
////

/**
 * Home
 */
.home {
	&.page {
		padding-top: 0; // Reset Header Mobile
		@include media(">=l") {
			padding-top: 0; // Reset Header Desktop
		}
	}

	// Header
	.header {
		.c-nav-top__link,
		.nav__trigger {
			color: #fff;
		}

		.nav__trigger {
			span {
				&,
				&::before,
				&::after {
					background: #fff;
				}
			}

			&.dropdown-is-active span {
				background: transparent;
			}
		}
	}
}


/**
 * Spotlight
 */
.home-spotlight {
	overflow: hidden;
	position: relative;

	// Swiper
	.swiper-wrapper {
		// Animations
		.o-spotlight__content {
			> * {
				opacity: 0;
				transform: translateY(25px);
				transition: all 0.4s;
			}
		}

		.o-spotlight__image {
			overflow: hidden;

			img {
				transition: all 0.3s;
			}
		}

		.swiper-slide-active {
			.o-spotlight__content {
				> * {
					opacity: 1;
					transform: none;

					@for $i from 0 to 5 {
						&:nth-child(#{$i + 1}) {
							transition-delay: $i * 0.1 + 0.3s;
						}
					}
				}
			}

			.o-spotlight__image {
				img {
					opacity: 1;
					transition-delay: 0.3s;
				}
			}
		}
	}

	.swiper-pagination {
		@extend .type-s;
		bottom: auto;
		display: none;
		flex: 1 0 auto;
		left: auto;
		position: relative;
		text-align: left;
		transform: none;
		width: auto;
		@include media(">=l") {
			display: block;
		}
	}
}


/**
 * Video
 */
.home-video {
	text-align: center;

	&__header {
	}

	&__body {
		margin-left: auto;
		margin-right: auto;
		@include media(">=m") {
			width: span(8);
		}
		@include media(">=l") {
			width: span(9 wide);
		}

		.c-video {
			overflow: hidden;
			padding-top: 56.25%;
			position: relative;
			width: 100%;

			iframe {
				@include absolute(top 0 left 0 bottom 0 right 0);
				height: 100%;
				width: 100%;
			}
		}
	}
}


/**
 * Therapy
 */
.home-therapy {
	&__header {
		position: relative;
		text-align: center;
		@include media(">=m") {
			padding-right: span(6 wide);
			text-align: left;
			h2 {
				padding-bottom: 0; // Reset u-trailing
			}
		}
	}

	&__body {
	}

	&__footer {
		text-align: center;
	}
}


/**
 * Post
 */
.home-post {
	&__header {
		position: relative;
		text-align: center;
		@include media(">=m") {
			padding-right: span(6 wide);
			text-align: left;
			h2 {
				padding-bottom: 0; // Reset u-trailing
			}
		}
	}

	&__nav {
		align-items: center;
		display: flex;
		font-size: 0;
		justify-content: center;
		@include media(">=m") {
			@include transform(y, 10);
			display: block;
			right: 0;
		}

		.swiper-button-next,
		.swiper-button-prev {
			@include relative(top 0 right 0 bottom 0 left 0);
			height: auto;
			margin: 0;
			width: auto;
		}

		.swiper-button-prev {
			margin-right: 1px;
			@include media(">=m") {
				@include fluid-type(margin-left, -4rem, -5rem);
				margin-right: 0;
			}
		}
	}

	&__body {
		.o-entry {
			margin-bottom: 0;
		}
	}
}

/**
 * Testimonials
 */
.testimonials {
	&__header {
		position: relative;
		text-align: center;
		@include media(">=m") {
			padding-right: span(6 wide);
			text-align: left;
			h2 {
				padding-bottom: 0; // Reset u-trailing
			}
		}
	}

	&__body {
		.o-entry {
			border-radius: 20px;
			margin-bottom: 0;

			&__wrap {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				justify-content: flex-start;
				order: 2;
				padding: 1rem;
				@include media(">=m") {
					padding: 2rem;
				}
			}

			&__header {
				align-items: flex-start;
				display: flex;
				gap: 0.5em;
				height: auto;
				justify-content: space-between;
				width: 100%;

				figure {
					align-items: center;
					background: #f5fcfb;
					border: 1px solid #d4d4d4;
					border-radius: 30rem;
					box-shadow: 0 0 0 2px #fff;
					color: #fff;
					display: inline-flex;
					flex: 0 0 auto;
					height: 4em;
					justify-content: center;
					position: relative;
					width: 4em;

					img {
						border-radius: inherit;
						display: block;
						height: 100%;
						object-fit: cover;
						overflow: hidden;
						width: 100%;
					}

					svg {
						height: 70%;
						width: 70%;
					}
				}

				h4 {
					flex: 1;

					small {
						display: block;
						font-size: 0.7em;
						opacity: 0.6;
					}
				}
			}

			&__body {
				@include media(">=m") {
					font-size: 0.9em;
				}
			}
		}
	}
}


/**
 * Pathology
 */
.home-pathology {
	@include media(">=m") {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	@include media(">=l") {
		justify-content: flex-end;
	}

	&__content {
		@include media(">=m") {
			width: span(8);
		}
		@include media(">=l") {
			width: span(7 wide);
		}
	}

	&__images {
		display: none;
		overflow: hidden;
		position: relative;
		z-index: 1;
		@include media(">=m") {
			display: block;
			margin-right: gutter();
			width: span(4);
		}
		@include media(">=l") {
			margin-right: -#{gutter()};
			width: span(4 wide);
		}

		img {
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;

			&.is-active {
				opacity: 1;
			}
		}
	}
}


/**
 * Course
 */
.home-course {
	// Swiper
	.swiper-wrapper {
		box-sizing: border-box;
		display: block;
		@include media(">=l") {
			display: flex;

			.o-course {
				align-items: stretch;
				display: flex;
				height: auto;

				&:nth-of-type(even) {
					.o-split {
						flex-direction: row;
					}
				}
			}

			// Animations
			.o-course__text {
				> * {
					opacity: 0;
					transform: translateY(25px);
					transition: all 0.4s;
				}
			}
			.o-course__image {
				figure {
					transition: all 0.3s;
				}
			}
			.swiper-slide-active {
				.o-course__text {
					> * {
						opacity: 1;
						transform: none;

						@for $i from 0 to 5 {
							&:nth-child(#{$i + 1}) {
								transition-delay: $i * 0.1 + 0.3s;
							}
						}
					}
				}

				.o-course__image figure {
					opacity: 1;
					transition-delay: 0.3s;
				}
			}
		}
	}

	.swiper-pagination {
		@include absolute(top 3rem right auto bottom auto left 3rem);
		@extend .type-s;
		display: none;
		transform: none;
		width: auto;
		@include media(">=l") {
			display: block;
		}
	}

	&.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
		background: transparent;
		border-radius: 0;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		font-weight: fontweight-get(bold);
		height: auto;
		margin: 0 1em 0 0;
		opacity: 0.6;
		padding-bottom: 2px;
		text-transform: uppercase;
		width: auto;

		&:hover {
			text-decoration: none;
		}

		&-active {
			background: transparent;
			border-bottom: 2px solid #fff;
			opacity: 1;
			padding-bottom: 5px;
		}
	}
}


.swiper-slide {
	height: auto;

	.o-entry__wrap {
		height: 100%;
	}
}
