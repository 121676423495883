////
/// ============================================================================
/// FORM
///
/// 01. Input, Textarea
/// 02. Textarea
/// 03. Input, Textarea disabled/readonly
/// 04. Number Input
/// 05. Select
/// 06. Checkbox/Radio
/// 07. File
/// 08. Label
/// ============================================================================
///
/// @group utils
////

/**
 * Input, Textarea
 */
#{text-inputs()},
textarea {
    @include fluid-type(height, 3rem, 4rem);
    @include fluid-type(padding-top padding-bottom, 0.5rem, 1rem);
    background-color: $input-background;
    border: $input-border-size $input-border-style $input-border-color;
    border-radius: $input-radius;
    box-shadow: $input-shadow;
    color: $input-color;
    display: block;
    font-family: $input-font-family;
    padding-left: 1em;
    padding-right: 1em;
    transition: $input-transition;
    width: 100%;

    &:focus {
        background-color: $input-background-focus;
        border: $input-border-size $input-border-style $input-border-color-focus;
        box-shadow: $input-shadow-focus;
        outline: none;
        transition: $input-transition;
    }
}


/**
 * Textarea
 */
textarea {
    max-width: 100%;
    resize: vertical;
    &[rows] {
        height: auto;
    }
}


/**
 * Input, Textarea disabled/readonly
 */
input,
textarea {
    &::placeholder {
        color: $input-placeholder-color;
    }

    &:disabled,
    &[readonly] {
        cursor: $input-cursor-disabled;
    }
}


/**
 * Number Input
 */
#{text-inputs(number)} {
    @if not($input-number-spinners) {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}


/**
 * Select
 */
select {
    @include fluid-type(height, 3rem, 4rem);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $input-background;
    border: $input-border-size $input-border-style $input-border-color;
    border-radius: $input-radius;
    box-shadow: $input-shadow;
    color: $input-color;
    font-family: $input-font-family;
    line-height: normal;
    padding: 0 1em;
    transition: $input-transition;
    width: 100%;

    &:focus {
        background-color: $input-background-focus;
        border: $input-border-size $input-border-style $input-border-color-focus;
        box-shadow: $input-shadow-focus;
        outline: none;
        transition: $input-transition;
    }

    //@if $select-triangle-color != transparent {
    //    @include triangle-background($select-triangle-color);
    //    background-size: 9px 6px;
    //    background-position: right center;
    //    background-origin: content-box;
    //    background-repeat: no-repeat;
    //}

    // Disabled state
    &:disabled {
        cursor: $input-cursor-disabled;
    }

    // Hide the dropdown arrow shown in newer IE versions
    &::-ms-expand {
        display: none;
    }

    &[multiple] {
        height: auto;
    }
}


/**
 * Checkbox/Radio
 */
[type="checkbox"],
[type="radio"] {
    position: absolute;
    top: 3px;
    // Styles for input/label siblings
    + label {
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 0;
        padding-left: 20px;
        line-height: normal;
        vertical-align: top;
    }
    label > & {
        margin-right: 0.5em;
    }
}


/**
 * File
 */
[type="file"] {
    width: 100%;
}


/**
 * Label
 */
label {
    color: $form-label-color;
    display: block;
    font-size: 15px;
    font-weight: $form-label-font-weight;
    line-height: $form-label-line-height;
    margin: 0;
    position: relative;
}


/**
 * Base
 */
.form {
    &__group {
        margin-bottom: 1rem;
        position: relative;
        &--privacy {
            display: block;
            padding-left: 1.5em;
            input {
                left: -1.5em;
            }
        }
    }
    &__label {
        font-size: 16px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }
    &__actions {
        @include clearfix;
        text-align: center;
    }
    .required {
        display: none;
    }
    &__fieldset {
        &--columns {
            .form__group {
                float: left;
                margin-right: 2%;
                width: 49%;
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
    &__help {
        color: color-get(gray);
        font-size: 0.7em;
        margin-top: 0.5rem;
    }

    .sent & {
        display: none;
    }
}


/**
 * Required
 */
.required {
    @extend .type-s ;
    display: block;
    color: color-get(error);
    @include media(">=s") {
        @include absolute(top 10px right 0);
    }
}
