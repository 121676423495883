////
/// ============================================================================
/// COMPONENT / Table Of Content
/// ============================================================================
///
/// @group components
////

.c-toc {
	&_title {
		margin-bottom: 1rem;
	}

	&_wrap {
		background: color-get(gray-lighter);
		border: 1px solid color-get(gray-light);
		padding: 1rem;
	}

	ol {
		counter-reset: item;
		list-style: none;
		margin: 0;
	}

	&_nav {
		padding-left: 0;

		& > li:not(:last-of-type) {
			border-bottom: 1px solid color-get(gray-light);
		}
	}

	li {
		color: color-get(primary);
	}

	a {
		align-items: stretch;
		display: flex;
		flex-wrap: nowrap;
		line-height: 1.25;
		padding: 0.5em 0;

		@include media(">=m") {
			font-size: 0.8em;
		}

		&:before {
			content: counters(item, ".") ". ";
			counter-increment: item;
			display: inline-block;
			flex-grow: 0;
			flex-shrink: 0;
			float: left;
			margin-right: 0.2em;
		}
	}
}
