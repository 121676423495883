////
/// ============================================================================
/// FOOTER
///
/// 01. Footer
/// ============================================================================
///
/// @group layout
////

/**
 * Footer
 */
.footer {
    a {
        color: color-get(gray);
        text-decoration: none;
        &:hover {
            color: color-get(primary);
        }
    }

    &__copyright {
        text-align: center;
    }
}