////
/// ============================================================================
/// LAZYLOAD
///
/// 01. Configuration
/// 02. Spinner
/// ============================================================================
///
/// @group vendors
////

/**
 * Configuration
 */
.lazyload {
    opacity: 0;
    position: relative;
    .no-js & {
        display: none;
    }
    &.loading {
        //background: 50% 50% url("images/loading.gif") no-repeat;
        opacity: 1;
        transition: opacity 300ms;
    }
    &.loaded {
        opacity: 1;
    }
}

.loading {
    &:after {
        @include center(center, 32px, 32px);
        @extend .o-spinner;
        content: '';
        opacity: 1;
        transition: opacity 300ms;
    }
}
.loaded {
    &:after {
        opacity: 0;
    }
}