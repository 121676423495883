////
/// ============================================================================
/// UTILITY
///
/// 01. Padding Leading
/// 02. Padding Trailing
/// 03. Background
/// 04. Position
/// 05. Object-fit
/// 06. Aspect Ratio
/// ============================================================================
///
/// @group utils
////

/**
 * Padding Leading
 */
.u-leading {
    &-xs {
        @include fluid-type(padding-top, 0.5rem, 1rem);
    }
    &-s {
        @include fluid-type(padding-top, 1rem, 2rem);
    }
    &-m {
        @include fluid-type(padding-top, 2rem, 4rem);
    }
    &-l {
        @include fluid-type(padding-top, 4rem, 6rem);
    }
    &-xl {
        @include fluid-type(padding-top, 6rem, 8rem);
    }
}


/**
 * Padding Trailing
 */
.u-trailing {
    &-xs {
        @include fluid-type(padding-bottom, 0.5rem, 1rem);
    }
    &-s {
        @include fluid-type(padding-bottom, 1rem, 2rem);
    }
    &-m {
        @include fluid-type(padding-bottom, 2rem, 4rem);
    }
    &-l {
        @include fluid-type(padding-bottom, 4rem, 6rem);
    }
    &-xl {
        @include fluid-type(padding-bottom, 6rem, 8rem);
    }
}


/**
 * Background
 */
.u-bg {
    &-primary,
    &-secondary,
    &-graydarker {
        &, h1, h2, h3 {
            color: #fff;
        }
    }
    &-primary {
        background-color: color-get(primary);
    }
    &-secondary {
        background-color: color-get(secondary);
    }
    &-graylighter {
        background-color: color-get(gray-lighter);
    }
    &-graylight {
        background-color: color-get(gray-light, $lighten: 10%);
    }
    &-graydarker {
        background-color: color-get(gray-darker);
    }
}


/**
 * Positions
 */
.u-cover {
    @include absolute(top 0 left 0, 0);
    height: 100%;
    width: 100%;
}


/**
 * Object-fit
 */
.u-object-cover {
    font-family: 'object-fit: cover;'; // Polyfill IE11 libraries/ofi.js
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.u-object-contain {
    font-family: 'object-fit: contain;'; // Polyfill IE11 libraries/ofi.js
    object-fit: contain;
    width: 100%;
    height: 100%;
}


/**
 * Aspect Ratio
 */
.u-ratio {
    position: relative;
    > * {
        @include absolute(top 0 left 0, 1);
        height: 100%;
        width: 100%;
    }
    &--11 {
        @include aspect-ratio(1 1);
    }
    &--109 {
        @include aspect-ratio(10 9);
    }
    &--169 {
        @include aspect-ratio(16 9);
    }
    &--4755 {
        @include aspect-ratio(47 55);
    }
}


/**
 * Border
 */
.u-border {
    &-top {
        border-top: 1px solid color-get(gray-light);
    }
    &-bottom {
        border-bottom: 1px solid color-get(gray-light);
    }
    &-horizontal {
        border-bottom: 1px solid color-get(gray-light);
        border-top: 1px solid color-get(gray-light);
    }
}


/**
 * Padding Block
 */
.u-pad {
    padding: 2em;
    @include media(">=m") {
        padding: 3em;
    }
    @include media(">=l") {
        padding: 4em;
    }
    @include media(">=xl") {
        padding: 6em;
    }
}
